<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card no-body class="p-4">
            <b-card-body>
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmit)" class="mb-5">
                  <h3 class="mb-0">
                    Встановлення пароля
                  </h3>

                  <p class="mb-4 text-muted">
                    Введіть пароль для подальношо входу в систему AML.point
                  </p>

                  <ValidationProvider
                    vid="email"
                    name="адреса електронної пошти"
                    rules="required|email"
                    v-slot="{ errors, dirty }"
                  >
                    <b-input-group class="mb-3">
                      <b-form-input
                        type="email"
                        v-model="email"
                        :disabled="true"
                        class="form-control-lg"
                        :state="errors.length > 0 ? false : null"
                      />

                      <b-form-invalid-feedback
                        :state="errors.length === 0"
                        v-for="(error, index) in errors"
                        v-bind:key="index"
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>

                  <ValidationProvider
                    name="пароль"
                    rules="required|password|confirmed:confirmation"
                    v-slot="{ errors }"
                  >
                    <b-input-group class="mb-3">
                      <b-form-input
                        type="password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-lg"
                        placeholder="Пароль"
                      />
                      <b-form-invalid-feedback :state="errors.length === 0">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>

                  <ValidationProvider vid="confirmation" v-slot="{ errors }">
                    <b-input-group class="mb-3">
                      <b-form-input
                        type="password"
                        v-model="password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-lg"
                        placeholder="Повторіть пароль"
                      />
                      <b-form-invalid-feedback :state="errors.length === 0">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>

                  <b-button
                    :disabled="submitting"
                    variant="primary"
                    type="submit"
                    class="btn-block btn-lg mb-4 font-weight-bold"
                    :class="{
                      'btn-light': submitting,
                      'btn-success': !submitting
                    }"
                  >
                    <span v-if="!submitting">
                      Встановити пароль
                    </span>
                    <span v-else>
                      Відправлення...
                    </span>
                  </b-button>
                </b-form>
              </ValidationObserver>

              <p class="text-center mb-0">
                <b-link to="/">
                  <img src="/img/logo_.svg" />
                </b-link>
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$route.query.email || !vm.$route.query.token)
        next({ name: "Login" });
      else next();
    });
  },
  name: "ResetPassword",
  created() {
    this.email = this.$route.query.email;
    this.token = this.$route.query.token;
  },
  data: () => ({
    submitting: false,
    email: "",
    password: "",
    password_confirmation: "",
    token: ""
  }),
  methods: {
    onSubmit() {
      this.submitting = true;
      api
        .resetPassword({
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token
        })
        .then(() => {
          api
            .loginUser({ email: this.email, password: this.password })
            .then(response => {
              this.$store
                .dispatch("auth/saveToken", {
                  token: response.data.success
                })
                .then(() => {
                  this.$store.dispatch("auth/getMe").then(() => {
                    this.submitting = false;
                    this.$router.push({
                      redirect: (window.location.href = window.location.origin)
                    });
                  });
                });
            })
            .catch(() => {
              this.submitting = false;
            });
        })
        .catch(error => {
          if (error.response.status !== 406) {
            this.submitting = false;
            return;
          }

          if (
            !error.response.data.errors ||
            !error.response.data.errors.email
          ) {
            this.submitting = false;
            return;
          }

          this.$refs.form.setErrors({
            email: "Токен не дійсний."
          });
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped>
.app {
  --gradient-to-color: #5bb836 !important;
  --gradient-from-color: #8279e7 !important;
  --gradient-color-stops: var(--gradient-from-color),
    var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  background-image: linear-gradient(
    to bottom right,
    var(--gradient-color-stops)
  ) !important;
}
a > img {
  width: 100px;
}
</style>
